import { MyIcon } from '@scanny-app/loopy-loop';

interface IBannerReferCustomer {
  handleBanner: () => void;
}

const BannerReferCustomer = ({ handleBanner }: IBannerReferCustomer) => {
  return (
    <div className="dashboard-banner bg-refer-customer relative hover-pointer" onClick={handleBanner}>
      <div className="heading w-30 md-w-50">Refer a customer</div>
      <div className="absolute top-16 right-60 md-right-32">
        <MyIcon icon="IconArrowUp" />
      </div>
    </div>
  );
};

export default BannerReferCustomer;
