import { Input, Select } from 'antd';
import getFilterConfig, { ReferralInitialFilters } from '../../../helpers/GetFilterConfig';
import { useEffect, useState } from 'react';
import { ISelectOption, filterOptions } from '@scanny-app/loopy-loop';
import { IDataSource } from '../../../components/Table/DataTable';
import { IFilterTable } from 'interfaces/FilterTable';
import FDReferrals from '../../../helpers/FilterData/Referrals';

function FTReferral({ dataSource, onFilterChange, combinationColumns, columnsToSearch, searchPlaceholder }: IFilterTable) {
  const { Option } = Select;
  const [filteredData, setFilteredData] = useState<IDataSource>([]);
  const storedFilter = JSON.parse(localStorage.getItem('filter') ?? '[]');
  const [filterState, setFilterState] = useState(getFilterConfig(storedFilter));

  const filteredDataFunction = FDReferrals({
    columnsToSearch: columnsToSearch,
    combinationColumns: combinationColumns,
    data: dataSource,
    searchInputValue: filterState.searchTerm.value as string,
    selectedCustomerStatusFilter: filterState.customerStatus.value as string[],
  });

  const handleFilterChange = (newValue: string[] | string, filterName: keyof ReferralInitialFilters) => {
    if (filterName === 'customerStatus') {
      if (newValue.length === 0) {
        localStorage.removeItem('filter');
      } else {
        const stringValue = Array.isArray(newValue) ? JSON.stringify(newValue) : newValue.toString();
        localStorage.setItem('filter', stringValue);
      }
    }

    setFilterState((prevState: ReferralInitialFilters) => ({
      ...prevState,
      [filterName]: {
        ...prevState[filterName],
        value: newValue,
      },
    }));
  };

  const handleReset = () => {
    setFilterState(getFilterConfig([]));
    localStorage.removeItem('filter');
  };

  useEffect(() => {
    setFilterState(getFilterConfig(storedFilter ? storedFilter : []));
  }, [dataSource]);

  useEffect(() => {
    setFilteredData(filteredDataFunction);
  }, [filterState]);

  useEffect(() => {
    onFilterChange(filteredData as []);
  }, [filteredData]);

  const customerStatusFilter = [
    { label: 'New', value: 'New' },
    { label: 'Assessing', value: 'Assessing' },
    { label: 'Booking', value: 'Booking' },
    { label: 'On Hire', value: 'On Hire' },
    { label: 'Off Hire', value: 'Off Hire' },
    { label: 'Recovery', value: 'Recovery' },
    { label: 'Settled', value: 'Settled' },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'Terminated', value: 'Terminated' },
  ];

  return (
    <div className="flex gap-16 mb-16 lg-flex-column tb-rental-request-filter">
      <div className="search-field w-30 md-w-full" data-name="Customer Status">
        <Select
          value={filterState.customerStatus.value}
          onChange={(value) => handleFilterChange(value, 'customerStatus')}
          filterOption={filterOptions}
          className="w-full referral-status-filter"
          placeholder="Customer Status"
          mode="multiple"
          showSearch
          allowClear
        >
          {customerStatusFilter.map((option: ISelectOption) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </div>

      <div className="search-field w-70 md-w-full flex flex-row gap-16" data-name="Search">
        <div className="w-full">
          <Input
            placeholder={searchPlaceholder}
            onChange={(event) => handleFilterChange(event.target.value as string, 'searchTerm')}
            value={filterState.searchTerm.value}
            className={'search-field w-full'}
            allowClear
          />
        </div>

        <div className="flex gap-8">
          <button
            className="btn-reset"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default FTReferral;
