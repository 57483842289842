import { Col, Form, FormInstance, Input, Select } from 'antd';
import { DatePicker } from 'antd/lib';
import { MyIcon, filterOptions, GetOptionsFromDB, ISelectOption, MultiFileUpload, IFile } from '@scanny-app/loopy-loop';
import { useState } from 'react';
interface SpeedUpReferralProps {
  handleFilesUpdate: (files: IFile[]) => void;
  form: FormInstance;
}
function SpeedUpReferral({ handleFilesUpdate, form }: SpeedUpReferralProps) {
  const { Option } = Select;
  const [insurers, setInsurers] = useState<ISelectOption[]>([]);

  const getInsurers = async () => {
    if (insurers.length === 0) {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Insurer`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: {},
        },
        (item) => item.name,
      );
      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      setInsurers(sortedOptions);
    }
  };

  return (
    <>
      <Col span={24}>
        <div className="p-24 flex flex-row icon-xl gap-24 p-24 rounded-6" style={{ backgroundColor: '#E8F2FA' }}>
          <MyIcon fill="#1D82CC" icon="IconCelebration" />
          <p className="m-0" style={{ color: '#1D82CC' }}>
            Your referral has been submitted, to speed up the referral process provide further information below
          </p>
        </div>
      </Col>
      <Col span={24}>
        <h5 className="m-0 text-sm">Documents</h5>
      </Col>
      <Col span={24}>
        <p className="m-0">Here you can upload any documents that can support your referral.</p>
        <p className="text-grey m-0">(Claim form (insurer), Drivers licences, Rego papers, Accident Photos, Tow Slip etc.)</p>
      </Col>
      <Col span={24}>
        <MultiFileUpload
          form={form}
          handleFilesUpdate={handleFilesUpdate}
          FileOptions={[
            { value: 'Rego Papers', label: 'Rego Papers' },
            { value: 'Accident Photos', label: 'Accident Photos' },
            { value: 'Claim Form', label: 'Claim Form' },
            { value: 'Drivers Licence', label: 'Drivers Licence' },
            { value: 'Tow Slip', label: 'Tow Slip' },
            { value: 'Other', label: 'Other' },
          ]}
        />
      </Col>
      <Col span={24}>
        <h5 className="m-0 text-sm">Additional comments</h5>
      </Col>
      <Col span={24}>
        <Form.Item name="additionalComments">
          <Input.TextArea
            maxLength={32768}
            rows={4}
            placeholder="Leave any additional comments that will assist with your referral here"
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <h5 className="m-0 text-sm">Claim Details</h5>
      </Col>
      <Col lg={12} xs={24}>
        <Form.Item name="customerClaimNumber" label="Customer Claim Number">
          <Input onChange={(e) => form.setFieldsValue({ customerClaimNumber: e.target.value.toUpperCase() })} />
        </Form.Item>
      </Col>
      <Col lg={12} xs={24}>
        <Form.Item name="customerInsurer" label="Customer Insurer" style={{ position: 'relative' }}>
          <Select showSearch filterOption={filterOptions} onFocus={getInsurers} onClick={getInsurers}>
            {insurers.length === 0 ? (
              <Option key={'Loading...'} value={'Loading...'} disabled>
                Loading...
              </Option>
            ) : (
              insurers.map((option: ISelectOption) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))
            )}
          </Select>
        </Form.Item>
      </Col>
      <Col lg={12} xs={24}>
        <Form.Item name="atFaultPartyClaimNumber" label="At Fault Claim Number">
          <Input onChange={(e) => form.setFieldsValue({ atFaultPartyClaimNumber: e.target.value.toUpperCase() })} />
        </Form.Item>
      </Col>
      <Col lg={12} xs={24}>
        <Form.Item name="atFaultPartyInsurer" label="AF Insurer" style={{ position: 'relative' }}>
          <Select showSearch filterOption={filterOptions} onFocus={getInsurers} onClick={getInsurers}>
            {insurers.length === 0 ? (
              <Option key={'Loading...'} value={'Loading...'} disabled>
                Loading...
              </Option>
            ) : (
              insurers.map((option: ISelectOption) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))
            )}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <h5 className="m-0 text-sm">Repair Details</h5>
      </Col>
      <Col lg={12} xs={24}>
        <Form.Item name="repairStartDate" label="Repair Start Date">
          <DatePicker className="box" format="DD/MM/YYYY" placeholder="Select the date" showToday={false} />
        </Form.Item>
      </Col>
      <Col lg={12} xs={24}>
        <Form.Item name="damagedCarState" label="Damaged Car State">
          <Select>
            <Option value="Assessing">Assessing</Option>
            <Option value="Waiting for Authorisation">Waiting for Authorisation</Option>
            <Option value="Total Loss">Total Loss</Option>
            <Option value="Repairable">Repairable</Option>
          </Select>
        </Form.Item>
      </Col>
    </>
  );
}

export default SpeedUpReferral;
