import { IDataSource } from 'components/Table/DataTable';
import { createContext } from 'react';

export interface IFDReferrals {
  combinationColumns: [string, string][];
  data: IDataSource;
  columnsToSearch: string[];
  selectedCustomerStatusFilter: string[];
  searchInputValue: string;
}

export interface IReferrals {
  id: string;
  customerName: string;
  customerEmail: string | null;
  customerMobile: string | null;
  customerFirstName: string;
  customerLastName: string;
  customerStage: string;
  customerStatus: string;
  createdDate: string;
  customerRego: string;
  customerMakeAndModel: string;
  liabilityConfirmed: boolean;
  atFaultPartyClaimNumber: string | null;
  // New properties added based on the provided data
  customerStreet: string;
  customerCity: string;
  customerPostalCode: string;
  customerState: string;
  additionalComments: string | null;
  atFaultPartyInsurer: string | null;
  Referral_Notes__c: string;
  // Orders__r properties
  'Orders__r.records.0.Referral_Payment_Notess__c': string;
  // Booking__r properties
  'Booking__r.totalSize': number;
  'Booking__r.done': boolean;
  'Booking__r.records.0.Vehicle__r.Name': string;
  'Booking__r.records.0.Vehicle__r.Make__r.Name': string;
  'Booking__r.records.0.Vehicle__r.Model__r.Name': string;
  'Booking__r.records.0.Booking_Start__c': string;
  'Booking__r.records.0.Status__c': string;
  'Booking__r.records.0.Booking_Finish_Return_Date_and_Time__c': string;
  bookingFinish: string;
  // Repairs__r properties
  'Repairs__r.totalSize': number;
  'Repairs__r.done': boolean;
  'Repairs__r.records.0.Start_Date__c': string;
  'Repairs__r.records.0.End_Date__c': string;
  customerInsurer: string;
  customerClaimNumber: string;
  damagedCarState: string;
}

interface IReferralsContext {
  setReloadData: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReferralContext = createContext<IReferralsContext>({
  setReloadData: () => {},
  setIsLoading: () => {},
});
