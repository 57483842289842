import { useEffect, useState } from 'react';
import { Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IReferrals } from 'interfaces/Referrals';
import { TableName } from 'types/TableName';
import FTReferral from './FilterTable/Referrals';
import { useNavigate } from 'react-router-dom';

export type IDataSource = IReferrals[];

interface Identifiable {
  id: string | number;
}

interface DataTableProps<T> {
  type: TableName;
  columns: ColumnsType<T>;
  data: IDataSource;
  columnsToSearch: string[];
  combinationColumns: [string, string][];
  className?: string;
  apiCompleted: boolean;
  searchPlaceholder: string;
  getRowKey: (record: T) => string | number;
}

const DataTable = <T extends object>({
  type,
  columns,
  data,
  columnsToSearch,
  combinationColumns,
  className,
  apiCompleted,
  searchPlaceholder,
  getRowKey,
}: DataTableProps<T & Identifiable>) => {
  const dynamicY = `calc(100vh - 300px)`;
  const [filteredData, setFilteredData] = useState<(T & Identifiable)[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(!apiCompleted);
  }, [apiCompleted]);

  const handleFilteredData = (newFilteredData: []) => {
    setFilteredData(() => newFilteredData);
  };

  const handleRowClick = (record: T & Identifiable, rowIndex: number | undefined) => {
    navigate(`/referrals/${record.id}`);
  };

  return (
    <>
      <FTReferral
        dataSource={data}
        onFilterChange={handleFilteredData}
        combinationColumns={combinationColumns}
        columnsToSearch={columnsToSearch}
        searchPlaceholder={searchPlaceholder}
      />

      {isLoading && <Skeleton active />}
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              const target = event.target as HTMLElement;
              const targetElement = target.closest('td');
              if (targetElement && !targetElement.querySelector('#afClaimNumberForm')) {
                handleRowClick(record, rowIndex);
              }
            },
          };
        }}
        id="tb-rental-request"
        rowClassName="hover-pointer"
        columns={columns}
        dataSource={filteredData}
        pagination={{ defaultPageSize: 12 }}
        scroll={{ x: '100%', y: dynamicY }}
        className={`${className} ${isLoading && 'hidden'}`}
        rowKey={getRowKey}
      />
    </>
  );
};

export default DataTable;
