import { MyIcon } from '@scanny-app/loopy-loop';
import { useRef, useState, useEffect, ReactElement } from 'react';

export interface ICarousel {
  contents: ReactElement[];
}

const Carousel = ({ contents }: ICarousel) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateScrollPosition = (index: number) => {
    if (containerRef.current) {
      const divElements = containerRef.current.childNodes;
      const divWidth = (divElements[0] as HTMLDivElement)?.offsetWidth;
      const containerWidth = containerRef.current.offsetWidth;
      const totalItems = contents.length;
      const gap = 12; // Adjust this if your gap is different

      let selectedScrollPosition = index * (divWidth + gap);

      // Center the items dynamically, excluding first and last
      if (totalItems > 2 && index >= 1 && index <= totalItems - 2) {
        selectedScrollPosition = index * (divWidth + gap) - (containerWidth - divWidth) / 2;
      }

      // Align the last item to the right
      if (index === totalItems - 1) {
        selectedScrollPosition = totalItems * (divWidth + gap) - containerWidth;
      }

      return selectedScrollPosition;
    }

    return 0;
  };

  const handleDotClick = (index: number) => {
    if (containerRef.current) {
      const selectedScrollPosition = calculateScrollPosition(index);
      containerRef.current.scrollTo({
        left: selectedScrollPosition,
        behavior: 'smooth',
      });
      setCurrentSlide(index);
    }
  };

  const handleNext = () => {
    if (currentSlide < contents.length - 1) {
      handleDotClick(currentSlide + 1);
    }
  };

  const handlePrev = () => {
    if (currentSlide > 0) {
      handleDotClick(currentSlide - 1);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const selectedScrollPosition = calculateScrollPosition(currentSlide);
      if (containerRef.current) {
        containerRef.current.scrollTo({
          left: selectedScrollPosition,
          behavior: 'smooth',
        });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentSlide, contents]);

  return (
    <div className="flex flex-column gap-8 relative">
      <div ref={containerRef} className="flex gap-12 carousel-container">
        {contents.map((item) => item)}
      </div>
      <div className="w-full flex gap-16 content-center middle ">
        <div className="btn-circle icon-xs hover-pointer" onClick={handlePrev}>
          <MyIcon icon="IconBack" />
        </div>
        <div className="flex gap-8 content-center middle">
          {contents.map((_, index) => (
            <div onClick={() => handleDotClick(index)} className="cursor-pointer" key={index}>
              <MyIcon
                icon={index === currentSlide ? 'IconDot' : 'IconOutlineDot'}
                fill={index === currentSlide ? '#3cceb3' : ''}
              />
            </div>
          ))}
        </div>
        <div className="btn-circle icon-xs hover-pointer" onClick={handleNext}>
          <MyIcon icon="IconNext" />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
