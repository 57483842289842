import { Modal, Form, Row, Col, message } from 'antd';
import { DatePicker, FormInstance } from 'antd';
import type { DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import { MyIcon } from '@scanny-app/loopy-loop';
interface PickUpConfirmationProps {
  open: boolean;
  onCancel: () => void;
  referralId: string;
  isLoading: boolean;
  onSubmit: (form: FormInstance) => void;
  repairStartDate: string;
  bookingStartDate: string;
}

function PickupConfirmation({ open, onSubmit, onCancel, isLoading, repairStartDate, bookingStartDate }: PickUpConfirmationProps) {
  const [form] = Form.useForm();

  const onChange: DatePickerProps['onChange'] = (date) => {
    form.setFieldsValue({
      repairEndDate: dayjs(date),
    });
  };
  const handleOk = async () => {
    try {
      await form.validateFields();
    } catch (errorInfo) {
      message.error('Please review field errors');
      return;
    }
    onSubmit(form);
  };
  const disableDate = (current: dayjs.Dayjs) => {
    return current && current <= dayjs(bookingStartDate).startOf('day');
  };
  return (
    <Modal
      className="custom-modal left-menu"
      width={360}
      centered
      okText="Request"
      cancelText="Cancel"
      open={open}
      onCancel={onCancel}
      onOk={handleOk}
      okButtonProps={{ disabled: isLoading }}
    >
      <Form
        initialValues={{ repairStartDate: repairStartDate ? dayjs(repairStartDate) : '' }}
        disabled={isLoading}
        form={form}
        className="w-full form-style-one"
        layout="vertical"
      >
        <Row gutter={[16, 12]}>
          <Col span={24}>
            <h6 className="m-0 text-sm font-semibold">Pick-up Confirmation</h6>
          </Col>
          <Col span={24}>
            <Form.Item name="pickUpDate" label="Pick-up Date" rules={[{ required: true, message: 'Select pick-up date' }]}>
              <DatePicker
                className="box"
                format="DD/MM/YYYY"
                disabledDate={disableDate}
                placeholder="Select pick-up date"
                onChange={onChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="px-8 flex flex-column gap-8">
              <h6 className="m-0 text-sm font-semibold">Confirm Repair Dates</h6>
              <div className="p-12 bg-blue-200 text-blue text-xs rounded-6 flex gap-8">
                <div className="icon-sm pt-4">
                  <MyIcon icon="IconInfo" />
                </div>
                <div>
                  Please ensure in your system that you record these date’s as the{' '}
                  <span className="font-semibold">repair start and end date’s</span> when submitting your proof of loss.
                </div>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <Form.Item
              name="repairStartDate"
              label="Repair Start Date"
              rules={[
                {
                  required: true,
                  message: 'Select repair start date',
                },
              ]}
            >
              <DatePicker
                disabled={repairStartDate !== undefined}
                className="box"
                format="DD/MM/YYYY"
                placeholder="Select repair start date"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="repairEndDate"
              label="Repair End Date"
              rules={[
                {
                  required: true,
                  message: 'Select repair start date',
                },
              ]}
            >
              <DatePicker
                className="box"
                disabledDate={disableDate}
                format="DD/MM/YYYY"
                placeholder="Select repair finish date"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default PickupConfirmation;
