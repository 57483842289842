import { IFDReferrals } from 'interfaces/Referrals';

function FDReferrals({
  combinationColumns,
  data,
  columnsToSearch,
  selectedCustomerStatusFilter,
  searchInputValue,
}: IFDReferrals) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const datafilter = data.filter((item: any) => {
    let isMatch = true;

    if (selectedCustomerStatusFilter.length > 0 && !selectedCustomerStatusFilter.includes(item.customerStage)) {
      isMatch = false;
    }

    if (isMatch && searchInputValue !== '') {
      const searchMatch = columnsToSearch.some((column: any) =>
        String(item[column]).toLowerCase().includes(searchInputValue.toLowerCase()),
      );

      const combinationMatch = (combinationColumns || []).some((pair: any) => {
        const combinedValue = String(item[pair[0]]) + ' ' + String(item[pair[1]]);
        return combinedValue.toLowerCase().includes(searchInputValue.toLowerCase());
      });

      isMatch = searchMatch || combinationMatch;
    }

    return isMatch;
  });

  return datafilter;
}

export default FDReferrals;
