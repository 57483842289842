import React from 'react';
import { FormInstance } from 'antd';
import { capitalizeWords } from '../../helpers/Strings/CapitalizeWords';

const fieldUppercase = (e: React.ChangeEvent<HTMLInputElement>, form: FormInstance, name: string) => {
  form.setFieldsValue({
    [name]: e.target.value.toUpperCase(),
  });
};

const fieldCapitalize = (e: React.ChangeEvent<HTMLInputElement>, form: FormInstance, name: string, firstLetter: boolean) => {
  form.setFieldsValue({ [name]: capitalizeWords(e.target.value, firstLetter) });
};

export { fieldUppercase, fieldCapitalize };
