import type { ColumnsType } from 'antd/es/table';
import { MyIcon } from '@scanny-app/loopy-loop';
import { Tooltip } from 'antd';
import { CustomerStatusComponent, LiabilityComponent } from '../../icons/General/MyStatus';
import { IReferrals } from 'interfaces/Referrals';
import FormUpdateAFClaim from '../../components/Form/FormUpdateAFClaim';


const getColumnKeys = (isMobile: boolean) => {
  if (isMobile) {
    return ['mobileCols'];
  } else {
    return [
      'id',
      'customerName',
      'customerMobile',
      'customerRego',
      'repairStart',
      'repairEnd',
      'bookingStart',
      'rentalRego',
      'afClaimNumber',
      'liability',
      'customerStatus',
      'statusNotes',
      'operation',
    ];
  }
};

const REFERRAL_DATA_COLUMNS = (navigate: (path: string) => void, isMobile: boolean): ColumnsType<IReferrals> => {
  const columnKeys = getColumnKeys(isMobile);

  const allColumns: ColumnsType<IReferrals> = [
    {
      title: 'Customer Name',
      key: 'customerName',
      width: 150,
      render: (record) => (
        <span>
          {record.customerFirstName} {record.customerLastName}
        </span>
      ),
      fixed: 'left',
      sorter: (a, b) => {
        const customerA = `${a.customerFirstName} ${a.customerLastName}`.toUpperCase(); // Convert names to uppercase for case-insensitive sorting
        const customerB = `${b.customerFirstName} ${b.customerLastName}`.toUpperCase();
        return customerA.localeCompare(customerB);
      },
    },

    {
      title: 'Customer Mobile',
      key: 'customerMobile',
      width: 140,
      render: (record: IReferrals) => {
        const mobileNumber = record.customerMobile ? record.customerMobile : '---';
        return <span>{mobileNumber}</span>;
      },
      fixed: 'left',
    },

    {
      title: 'Customer Rego',
      key: 'customerRego',
      width: 130,
      render: (record: IReferrals) => {
        const customerRego = record.customerRego ? record.customerRego : '---';
        return <span>{customerRego}</span>;
      },
    },

    {
      title: 'Repair Start',
      key: 'repairStart',
      render: (record: IReferrals) => {
        const repairStart = record['Repairs__r.records.0.Start_Date__c']
          ? new Date(record['Repairs__r.records.0.Start_Date__c']).toLocaleDateString('en-AU')
          : '---';
        return <span>{repairStart}</span>;
      },
      width: 120,
      sorter: (a: IReferrals, b: IReferrals) => {
        const dateA = a['Repairs__r.records.0.Start_Date__c'] ? new Date(a['Repairs__r.records.0.Start_Date__c']) : new Date(0); // Default date for empty records
        const dateB = b['Repairs__r.records.0.Start_Date__c'] ? new Date(b['Repairs__r.records.0.Start_Date__c']) : new Date(0); // Default date for empty records
        return dateA.getTime() - dateB.getTime();
      },
    },

    {
      title: 'Booking Start',
      key: 'bookingStart',
      width: 140,
      render: (record: IReferrals) => {
        const bookingStart = record['Booking__r.records.0.Booking_Start__c']
          ? new Date(record['Booking__r.records.0.Booking_Start__c']).toLocaleDateString('en-AU')
          : '---';
        return <span>{bookingStart}</span>;
      },

      sorter: (a: IReferrals, b: IReferrals) => {
        const dateA = a['Booking__r.records.0.Booking_Start__c']
            ? new Date(a['Booking__r.records.0.Booking_Start__c'])
            : new Date(0); // Default date for empty records
        const dateB = b['Booking__r.records.0.Booking_Start__c']
            ? new Date(b['Booking__r.records.0.Booking_Start__c'])
            : new Date(0); // Default date for empty records
        return dateA.getTime() - dateB.getTime();
      },
    },
    {
      title: 'Repair End',
      key: 'repairEnd',
      width: 100,
      render: (record: IReferrals) => {
        const repairEnd = record['Repairs__r.records.0.End_Date__c']
          ? new Date(record['Repairs__r.records.0.End_Date__c']).toLocaleDateString('en-AU')
          : '---';
        return <span>{repairEnd}</span>;
      },
    },

    {
      title: 'Rental Rego',
      key: 'rentalRego',
      width: 110,
      render: (record: IReferrals) => {
        const rentalRego = record['Booking__r.records.0.Vehicle__r.Name']
          ? record['Booking__r.records.0.Vehicle__r.Name']
          : '---';
        return <span>{rentalRego}</span>;
      },
    },
    {
      title: 'Customer Status',
      key: 'customerStatus',
      width: 150,
      render: (record: IReferrals) => (
        <div>
          <CustomerStatusComponent status={record.customerStage} />
        </div>
      ),
      sorter: (a, b) => a.customerStage.localeCompare(b.customerStage),
    },
    {
      title: 'Status Notes',
      key: 'statusNotes',
      width: 140,
      render: (record: IReferrals) => {
        const statusNotes = record['Orders__r.records.0.Referral_Payment_Notess__c'];
        return (
          <Tooltip placement="bottomRight" title={statusNotes}>
            <div className="truncate">{statusNotes ? statusNotes : '---'}</div>
          </Tooltip>
        );
      },
    },

    {
      title: 'AF Claim Number',
      key: 'afClaimNumber',
      width: 220,
      render: (record: IReferrals) => (
        <span>{record.atFaultPartyClaimNumber ? record.atFaultPartyClaimNumber : <FormUpdateAFClaim id={record.id} />}</span>
      ),
    },
    {
      title: 'Liability',
      key: 'liability',
      width: 90,
      render: (record) => (
        <div>
          <LiabilityComponent status={record.liabilityConfirmed} />
        </div>
      ),
      sorter: (a: { liabilityConfirmed: boolean }, b: { liabilityConfirmed: boolean }) => {
        // Sort true values before false values
        if (a.liabilityConfirmed === b.liabilityConfirmed) {
          return 0;
        }
        return a.liabilityConfirmed ? -1 : 1; // or use 1 for true first and -1 for false first
      },
    },

    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record) => (
        <button onClick={() => navigate(`/referrals/${record.id}`)} className="btn-action-table content-center w-full h-full">
          <MyIcon icon="IconNext" />
        </button>
      ),
    },

    {
      title: 'Display Data',
      key: 'mobileCols',
      sortOrder: 'ascend',
      render: (record: IReferrals) => (
        <div className="flex flex-column gap-8 box-1 mb-12" onClick={() => navigate(`/referrals/${record.id}`)}>
          <div className="flex content-space-between middle  p-12-16">
            <div className="flex gap-8 icon-xs middle">
              <MyIcon icon="IconCustomer" />
              <h5 className="text-xs font-semibold m-0">{record.customerName}</h5>
            </div>
            <CustomerStatusComponent status={record.customerStage} />
          </div>
          <div className="flex content-space-between middle  p-12-16 border-y">
            <div>{record.atFaultPartyClaimNumber ? record.atFaultPartyClaimNumber : '---'}</div>
            <div>{record.customerMobile}</div>
          </div>
          <div className="flex  middle  p-12-16">
            <div className="w-40 icon-xs middle flex gap-8">
              <MyIcon icon={'IconCarRentals'} />
              {record.customerRego ? record.customerRego : '---'}
            </div>
            <div className="w-40 icon-xs middle flex gap-8">
              <MyIcon icon={'IconCarRentals'} />
              {record['Booking__r.records.0.Vehicle__r.Name'] ? record['Booking__r.records.0.Vehicle__r.Name'] : '---'}
            </div>
          </div>
        </div>
      ),
    },
  ];
  return allColumns.filter((column) => column.key && columnKeys.includes(column.key as string));
};

export { REFERRAL_DATA_COLUMNS };
