import { MyIcon } from '@scanny-app/loopy-loop';

interface IStatus {
  status: string;
}

interface IStatusBool {
  status: boolean;
}

interface IPickupComponent {
  status: string;
  disabled: boolean;
  onClick: () => void;
}
const PaymentStatusComponent = ({ status }: IStatus) => {
  switch (status) {
    case 'Referral rejected':
      return <div className="text-red">Referral rejected</div>;

    case 'Paid':
      return <div className="text-green">Paid</div>;

    case 'Pending payment':
      return <div className="text-blue">Pending payment</div>;

    case 'Pending approval':
      return <div className="text-grey">Pending approval</div>;

    default:
      return <div>{status}</div>;
  }
};

const LiabilityComponent = ({ status }: IStatusBool) => {
  if (status) {
    return <div className="text-global">Accepted</div>;
  } else {
    return <div className="text-dark-blue">Not yet ...</div>;
  }
};

const CustomerStatusComponent = ({ status }: IStatus) => {
  switch (status) {
    case 'New':
      return <div className="status-yellow referral-status">New</div>;
    case 'Assessing':
      return <div className="status-gold referral-status">Assessing</div>;
    case 'Booking':
      return <div className="status-orange referral-status">Booking</div>;
    case 'On Hire':
      return <div className="status-blue referral-status">On Hire</div>;
    case 'Off Hire':
      return <div className="status-blue referral-status">Off Hire</div>;
    case 'Recovery':
      return <div className="status-aqua referral-status">Recovery</div>;
    case 'Settled':
      return <div className="status-green referral-status">Settled</div>;
    case 'Terminated':
      return <div className="status-red referral-status">Terminated</div>;
    case 'Cancelled':
      return <div className="status-grey referral-status">Cancelled</div>;

    default:
      return <div>{status}</div>;
  }
};

const PickupComponent = ({ status, disabled, onClick }: IPickupComponent) => {
  switch (status) {
    case 'Request Pick-up':
      return (
        <div
          className={`btn-pickup ${disabled ? 'disabled hover-disabled' : 'hover-pointer'}`}
          onClick={disabled ? undefined : onClick}
        >
          Request Pick-up
        </div>
      );

    case 'Picked Up':
      return (
        <div className="flex gap-8 text-green-300 icon-xs middle">
          <MyIcon icon={'IconApproved'} />
          Picked Up
        </div>
      );

    case 'Pick-up Requested':
      return <div className="text-green-300">Pick-up Requested...</div>;

    default:
      return <div>{status}</div>;
  }
};
export { PaymentStatusComponent, LiabilityComponent, CustomerStatusComponent, PickupComponent };
