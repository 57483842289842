import { Auth } from 'aws-amplify';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CustomerStatusComponent } from '../../icons/General/MyStatus';
import { FooterNavigation, getOffset, LargeLoading, MyIcon, ThirdNavigation } from '@scanny-app/loopy-loop';
import { useNavigate, useParams } from 'react-router-dom';
import { IReferrals, ReferralContext } from '../../interfaces/Referrals';
import PickupConfirmation from '../../components/Modals/PickupConfirmation';
import MissingDetails from '../../components/Card/MissingDetails';
import Carousel from '../../helpers/Carousel';
import FormUpdateReferral from '../../components/Form/FormUpdateReferral';
import { FormInstance, message } from 'antd';

type statusType = 'icon' | 'desc';
function ViewReferral() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<IReferrals>();
  const [disableForm, setDisableForm] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [showPickupModal, setShowPickupModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [getDataError, setGetDataError] = useState<string | null>(null);
  const [files, setFiles] = useState<string[]>([]);
  const CustomCard = ({ label, value, border, type }: { label: string; value: string; border?: boolean; type?: statusType }) => {
    return (
      <div className={`text-xs w-50 ${border ? 'border' : ''} rounded-6 md-w-full flex gap-12 middle p-24`}>
        <div>{label}:</div>
        {type === 'icon' ? (
          <CustomerStatusComponent status={value} />
        ) : type === 'desc' ? (
          <div className="text-xs">{value}</div>
        ) : (
          <div className="font-semibold text-base">{value}</div>
        )}
      </div>
    );
  };

  useEffect(() => {
    let run = true;
    Auth.currentSession()
      .then((responseData) => {
        const token = responseData.getAccessToken().getJwtToken();
        axios
          .post(`${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Referral`, JSON.stringify({ id: id }), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (run) {
              if (response.data && response.data[0]) {
                const totalBookingSize = response.data[0]['Booking__r.totalSize'];
                setData({
                  ...response.data[0],
                  bookingFinish:
                    response.data[0][`Booking__r.records.${totalBookingSize - 1}.Booking_Finish_Return_Date_and_Time__c`],
                });
                setIsLoading(false);
              } else {
                setIsLoading(false);
                setGetDataError('Failed to retrieve records. Please try again later.');
              }
            }
          })
          .catch(() => {
            setIsLoading(false);
            setGetDataError('Something went wrong. Please try again.');
          });
        axios
          .post(`${process.env.REACT_APP_ENDPOINT_URL}/v1/fetch/file/Referral`, JSON.stringify({ id: id }), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setFiles(response.data);
          });
      })
      .catch(() => {
        setIsLoading(false);
        setGetDataError('Something went wrong. Please try again.');
      });
    return () => {
      run = false;
    };
  }, [id, reloadData]);

  const values = {
    customerName: data?.customerName ? data?.customerName : '',
    customerRego: data?.customerRego ? data?.customerRego : '',
    submitted: data?.createdDate ? new Date(data.createdDate).toLocaleDateString('en-AU') : '',
    customerStatus: data?.customerStage ? data.customerStage : '',
    booked: data?.['Booking__r.records.0.Booking_Start__c']
      ? new Date(data?.['Booking__r.records.0.Booking_Start__c']).toLocaleDateString('en-AU')
      : '',
    statusNotes: data?.['Orders__r.records.0.Referral_Payment_Notess__c']
      ? data['Orders__r.records.0.Referral_Payment_Notess__c']
      : '',
    repairEndDate: data?.['Repairs__r.records.0.End_Date__c'] ? data['Repairs__r.records.0.End_Date__c'] : '',
  };

  if (isLoading) {
    return <LargeLoading />;
  }
  interface SubmitData {
    id: string | undefined;
    repairEndDate: string;
    pickUpDate: string;
    repairStartDate?: string;
  }
  const handlePickup = (form: FormInstance) => {
    setIsLoading(true);
    const formData = form.getFieldsValue();
    let submitData: SubmitData = {
      id: id,
      repairEndDate: formData.repairEndDate.format('YYYY-MM-DD'),
      pickUpDate: `${formData.pickUpDate.format('YYYY-MM-DD')}T16:59${getOffset(Intl.DateTimeFormat().resolvedOptions().timeZone)}`,
    };
    if (formData.repairStartDate) {
      submitData.repairStartDate = formData.repairStartDate.format('YYYY-MM-DD');
    }
    Auth.currentSession().then((sessionData) => {
      const token = sessionData.getAccessToken().getJwtToken();
      axios
        .post(`${process.env.REACT_APP_ENDPOINT_URL}/update/Referral/${id}`, JSON.stringify({ ...submitData }), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setShowPickupModal(false);
          setReloadData(!reloadData);
          message.success('Referral updated successfully');
        })
        .catch(() => {
          message.error('Error updating referral');
          setIsLoading(false);
        });
    });
  };

  const missingDetailsFields = [
    { label: 'At Fault Claim Number', name: 'atFaultPartyClaimNumber' },
    { label: 'Customer Claim Number', name: 'customerClaimNumber' },
    { label: 'Mobile Number', name: 'customerMobile' },
    { label: 'Customer Email', name: 'customerEmail' },
    { label: 'Customer Insurer', name: 'customerInsurer' },
    { label: 'At Fault Insurer', name: 'atFaultPartyInsurer' },
  ];
  const carouselMissingDetails = missingDetailsFields
    .map((field) => {
      if (field.label === 'Mobile Number' && data && !data.customerMobile) {
        return <MissingDetails key={field.label} label={field.label} name={field.name} />;
      } else if (field.label === 'Email' && data && !data.customerEmail) {
        return <MissingDetails key={field.label} label={field.label} name={field.name} />;
      } else if (field.label === 'Customer Insurer' && data && !data.customerInsurer) {
        return <MissingDetails key={field.label} label={field.label} name={field.name} />;
      } else if (field.label === 'At Fault Insurer' && data && !data.atFaultPartyInsurer) {
        return <MissingDetails key={field.label} label={field.label} name={field.name} />;
      } else if (field.label === 'At Fault Claim Number' && data && !data.atFaultPartyClaimNumber) {
        return <MissingDetails key={field.label} label={field.label} name={field.name} />;
      } else if (field.label === 'Customer Claim Number' && data && !data.customerClaimNumber) {
        return <MissingDetails key={field.label} label={field.label} name={field.name} />;
      }
      return null;
    })
    .filter((item): item is React.ReactElement => item !== null);

  const showPickupButton = values.customerStatus === 'On Hire';
  const disablePickupButton = values.repairEndDate !== '';
  const showFooter = values.customerStatus !== 'Cancelled' && values.customerStatus !== 'Terminated';

  const Node = (
    <div className={`flex content-end w-full`}>
      {disableForm ? (
        <div className={`flex content-space-between ${showPickupButton ? 'w-full' : ''}`}>
          <button className="btn-outline-global" onClick={() => setDisableForm(false)}>
            Edit
          </button>

          {showPickupButton && (
            <button className="btn-submit" onClick={() => setShowPickupModal(true)} disabled={disablePickupButton}>
              {disablePickupButton ? 'Pick up requested' : 'Request pickup'}
            </button>
          )}
        </div>
      ) : (
        <div className="w-full flex content-space-between">
          <button className="btn-outline-global" onClick={() => setDisableForm(true)}>
            Cancel
          </button>
          <button className="btn-submit hover-pointer" onClick={() => setShowConfirmation(true)}>
            Save
          </button>
        </div>
      )}
    </div>
  );

  return (
    <ReferralContext.Provider value={{ setReloadData, setIsLoading: setIsLoadingSubmit }}>
      {isLoadingSubmit && <LargeLoading customText={`Just a moment, we're updating the Referral details....`} />}
      {getDataError ? (
        <div className="container-2 p-24 flex flex-column content-center middle gap-16">
          <MyIcon icon="IconErrorInCloud" />
          <h1 className="heading-page-content">{getDataError}</h1>
          <a className="no-underline" href="/referrals">
            <button className="btn-secondary flex middle"> Back to referrals</button>
          </a>
        </div>
      ) : (
        <>
          <ThirdNavigation
            menuTitle={`${values.customerName} ${values.customerRego ? `- ${values.customerRego}` : ''}`}
            onBack={() => navigate(`/referrals/`)}
            borderBottom={true}
            slug={'Referrals'}
          />

          <div id="inner-content" className="container-2 gap-24">
            {carouselMissingDetails.length > 0 && <Carousel contents={carouselMissingDetails} />}
            <div className="flex flex-column gap-16">
              <div className="flex flex-row md-flex-column border-grey rounded-6">
                <CustomCard label="Submitted" value={values.submitted} />
                <CustomCard label="Booked" value={values.booked} />
              </div>
              <div className="flex flex-row md-flex-column  border-grey rounded-6">
                <CustomCard label="Customer Status" type="icon" value={values.customerStatus} border={true} />
                <CustomCard label="Status Notes" type="desc" value={values.statusNotes} border={true} />
              </div>
            </div>
            {data && (
              <>
                <FormUpdateReferral
                  state={{ disableForm, setDisableForm }}
                  data={data}
                  modal={{ showConfirmation, setShowConfirmation }}
                  files={files}
                />
                <PickupConfirmation
                  open={showPickupModal}
                  onCancel={() => setShowPickupModal(false)}
                  referralId={data.id}
                  onSubmit={handlePickup}
                  isLoading={false}
                  repairStartDate={data['Repairs__r.records.0.Start_Date__c']}
                  bookingStartDate={data['Booking__r.records.0.Booking_Start__c']}
                />
              </>
            )}
          </div>
          {showFooter && <FooterNavigation node={Node} />}
        </>
      )}
    </ReferralContext.Provider>
  );
}

export default ViewReferral;
