type FilterConfig = {
  value: string[] | string;
  disabled: boolean;
  hidden: boolean;
};

export type ReferralInitialFilters = {
  [key: string]: FilterConfig;
  customerStatus: FilterConfig;
  searchTerm: FilterConfig;
};

const initialReferralFilters: ReferralInitialFilters = {
  customerStatus: { value: [], disabled: false, hidden: false },
  searchTerm: { value: '', disabled: false, hidden: false },
};

const getFilterConfig = (status: string[]) => {
  const updatedReferralFilters = {
    ...initialReferralFilters,
  };

  if (status) {
    return {
      ...updatedReferralFilters,
      customerStatus: {
        ...updatedReferralFilters.customerStatus,
        value: status,
        disabled: false,
      },
    };
  } else {
    return updatedReferralFilters;
  }
};

export default getFilterConfig;
