import { LargeLoading, MyIcon } from '@scanny-app/loopy-loop';
import PopupMissingDetails from '../../components/Modals/PopupMissingDetails';
import React, { useContext, useState } from 'react';
import { Form, message } from 'antd';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ReferralContext } from '../../interfaces/Referrals';

interface ICarouselMissingDetails {
  label: string;
  name: string;
}

function MissingDetails({ label, name }: ICarouselMissingDetails) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [formReferral] = Form.useForm();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { setReloadData } = useContext(ReferralContext);

  const handleSubmit = () => {
    setIsLoading(true);
    updateMissingDetails();
    setShowEditModal(false);
  };

  const updateMissingDetails = async () => {
    try {
      await formReferral.validateFields();
    } catch (errorInfo) {
      message.error('Please review field errors');
      return;
    }

    const formValues = formReferral.getFieldsValue();
    const formatValues = {
      ...(formValues.atFaultPartyClaimNumber && {
        atFaultPartyClaimNumber: formValues.atFaultPartyClaimNumber.toUpperCase(),
      }),
      ...(formValues.customerClaimNumber && {
        customerClaimNumber: formValues.customerClaimNumber.toUpperCase(),
      }),
      ...(formValues.customerInsurer && {
        customerInsurer: formValues.customerInsurer,
      }),
      ...(formValues.atFaultPartyInsurer && {
        atFaultPartyInsurer: formValues.atFaultPartyInsurer,
      }),
    };

    try {
      const sessionData = await Auth.currentSession();
      const token = sessionData.getAccessToken().getJwtToken();

      axios
        .post(`${process.env.REACT_APP_ENDPOINT_URL}/update/Referral/${id}`, JSON.stringify({ ...formatValues, id: id }), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setReloadData((prevState) => !prevState);

          message.success(`${label} updated successfully`);
          setShowEditModal(false);
        })
        .catch((error) => {
          message.error(`Error updating the ${label}`);
          setIsLoading(false);
        });
    } catch (error) {
      message.error(`Error updating the ${label}`);
      setIsLoading(false);
      setShowEditModal(false);
    }
  };

  return (
    <>
      {isLoading && <LargeLoading customText={`One moment, submitting the ${label}...`} />}
      <PopupMissingDetails
        open={showEditModal}
        onCancel={() => setShowEditModal(false)}
        onSubmit={handleSubmit}
        isLoading={false}
        data={{ label, name }}
        form={formReferral}
      />

      <div className="p-32-24 flex gap-20 border-grey rounded-6 content-space-between md-flex-column carousel-item">
        <div className="flex gap-12 middle card-carousel md-flex-column md-start">
          <MyIcon icon="IconEdit" />
          <div className="flex flex-column gap-4">
            <div className="font-semibold text-xs">Missing details</div>
            <div className="text-base md-text-sm">
              Enter the <span className="font-semibold">{label}</span>
            </div>
          </div>
        </div>
        <button className="rounded-100 btn-outline-global" onClick={() => setShowEditModal(true)}>
          Enter details
        </button>
      </div>
    </>
  );
}

export default MissingDetails;
