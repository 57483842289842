import { Col, Form, Input, message, Modal, Row, Select } from 'antd';
import {
  Address,
  capitalizeWords,
  fieldCapitalize,
  fieldUppercase,
  filterOptions,
  GetOptionsFromDB,
  ISelectOption,
  MyIcon,
  MultiFileUpload,
  IFile,
} from '@scanny-app/loopy-loop';
import { IReferrals, ReferralContext } from '../../interfaces/Referrals';
import React, { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { DatePicker } from 'antd/lib';

interface IConfirmationState {
  showConfirmation: boolean;
  setShowConfirmation: (showConfirmation: boolean) => void;
}

interface IDisableForm {
  disableForm: boolean;
  setDisableForm: (disableForm: boolean) => void;
}

interface ReferralDetailsProps {
  state: IDisableForm;
  data: IReferrals;
  modal: IConfirmationState;
  files: string[];
}

function FormUpdateReferral({ state, data, modal, files }: ReferralDetailsProps) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [insurers, setInsurers] = useState<ISelectOption[]>([]);
  const { setReloadData, setIsLoading } = useContext(ReferralContext);
  const [required, setRequired] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<IFile[]>([]);
  const handleSubmit = async () => {
    try {
      await form.validateFields();
    } catch (errorInfo) {
      message.error('Please review field errors');
      modal.setShowConfirmation(false);
      return;
    }
    modal.setShowConfirmation(false);

    if (setIsLoading) {
      setIsLoading(true);
      try {
        await updateAFClaim(data.id, setIsLoading);
      } catch (error) {
        message.error('Error updating claim');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onChangeCustomerFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setRequired(value !== '');
    fieldCapitalize(e, form, 'customerFirstName', true);
  };
  const handleFilesUpdate = async (files: IFile[]) => {
    setFilesToUpload(files);
  };
  const updateAFClaim = async (id: string, setIsLoading: (value: boolean) => void) => {
    const formatValues = {
      ...form.getFieldsValue(),
      customerStreet: capitalizeWords(form.getFieldValue('customerStreet')),
      customerStreetLine2: capitalizeWords(form.getFieldValue('customerStreetLine2')),
      customerCity: capitalizeWords(form.getFieldValue('customerCity')),
      files: filesToUpload,
    };

    // Remove keys with undefined or empty string values
    Object.keys(formatValues).forEach((key) => {
      if (formatValues[key] === '' || formatValues[key] === undefined) {
        delete formatValues[key];
      }
    });
    try {
      const sessionData = await Auth.currentSession();
      const token = sessionData.getAccessToken().getJwtToken();
      await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/update/Referral/${id}`,
        JSON.stringify({ ...formatValues, id: id }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setIsLoading(false);
      setReloadData((prevState) => !prevState);
      state.setDisableForm(true);

      message.success('Referral updated successfully');
    } catch (error) {
      setIsLoading(false);
      state.setDisableForm(true);
      message.error('Error updating the referral');
    }
  };

  const getInsurers = async () => {
    if (insurers.length === 0) {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Insurer`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: {},
        },
        (item) => item.name,
      );
      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      setInsurers(sortedOptions);
    }
  };

  // initialValuesForm
  const bookingStartDate = data['Booking__r.records.0.Booking_Start__c']
    ? new Date(data['Booking__r.records.0.Booking_Start__c']).toLocaleDateString('en-AU')
    : '';
  const repairStartDate = data['Repairs__r.records.0.Start_Date__c']
    ? new Date(data['Repairs__r.records.0.Start_Date__c']).toLocaleDateString('en-AU')
    : '';
  const bookingFinish = data['bookingFinish'] ? new Date(data['bookingFinish']).toLocaleDateString('en-AU') : '';
  const repairEndDate = data['Repairs__r.records.0.End_Date__c']
    ? new Date(data['Repairs__r.records.0.End_Date__c']).toLocaleDateString('en-AU')
    : '';
  const replacementMakeModel =
    data['Booking__r.records.0.Vehicle__r.Make__r.Name'] && data['Booking__r.records.0.Vehicle__r.Model__r.Name']
      ? `${data['Booking__r.records.0.Vehicle__r.Make__r.Name']} ${data['Booking__r.records.0.Vehicle__r.Model__r.Name']}`
      : '';
  const replacementVehicleRegistration = data?.['Booking__r.records.0.Vehicle__r.Name'] ?? '';
  const customerFirstName = data.customerFirstName ?? '';
  const customerLastName = data.customerLastName ?? '';
  const customerMobile = data.customerMobile ?? '';
  const customerEmail = data.customerEmail ?? '';
  const customerAddress =
    `${data.customerStreet ?? ''} ${data.customerCity ? `${data.customerCity},` : ''} ${data.customerState ?? ''} ${data.customerPostalCode ?? ''}`.trim();
  const customerRego = data.customerRego ?? '';
  const customerMakeAndModel = data.customerMakeAndModel ?? '';
  const damagedCarState = data.damagedCarState ?? '';
  const additionalComment = data.additionalComments ?? '';
  const customerInsurer = data.customerInsurer ?? '';
  const customerClaimNumber = data.customerClaimNumber ?? '';
  const atFaultPartyClaimNumber = data.atFaultPartyClaimNumber ?? '';
  const atFaultPartyInsurer = data.atFaultPartyInsurer ?? '';

  return (
    <>
      <Modal
        open={modal.showConfirmation}
        onCancel={() => modal.setShowConfirmation(false)}
        onOk={handleSubmit}
        className="custom-modal"
        width={400}
        centered
      >
        <h5 className="text-sm font-semibold m-0">Are you sure you want to update the data?</h5>
        <p className="mt-4 mb-16">This action will save the data to this referral and cannot be undone.</p>
      </Modal>
      <Form id="form-data" className="w-full form-style-one" layout="vertical" form={form} disabled={false}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <h5 className="m-0 text-sm">Replacement Vehicle</h5>
          </Col>
          <Col lg={12} xs={24}>
            <div className="label">Replacement Vehicle Registration</div>
            <div className="box bg-box">{replacementVehicleRegistration}</div>
          </Col>
          <Col lg={12} xs={24}>
            <div className="label">Make & Model</div>
            <div className="box bg-box ">{replacementMakeModel}</div>
          </Col>
          <Col lg={12} xs={24}>
            <div className="label">Booking Start Date</div>
            <div className="box bg-box ">{bookingStartDate}</div>
          </Col>
          <Col lg={12} xs={24}>
            <div className="label">Booking End Date</div>
            <div className="box bg-box ">{bookingFinish}</div>
          </Col>
          <Col span={24}>
            <div className="border-t-dashed mt-16 pt-32">
              <h5 className="text-sm m-0">Customer Details</h5>
            </div>
          </Col>
          <Col lg={12} xs={24}>
            {customerFirstName === '' && !state.disableForm ? (
              <Form.Item name="customerFirstName" label="Customer First Name">
                <Input onChange={onChangeCustomerFirstName} />
              </Form.Item>
            ) : (
              <>
                <div className="label">Customer First Name</div>
                <div className="box bg-box ">{customerFirstName}</div>
              </>
            )}
          </Col>
          <Col lg={12} xs={24}>
            {(customerLastName === '' || customerLastName === 'TBC') && !state.disableForm ? (
              <Form.Item
                name="customerLastName"
                label="Customer Last Name"
                rules={[{ required: required, message: 'Please provide the last name' }]}
              >
                <Input onChange={(e) => fieldCapitalize(e, form, 'customerLastName', true)} />
              </Form.Item>
            ) : (
              <>
                <div className="label">Customer Last Name</div>
                <div className="box bg-box ">{customerLastName}</div>
              </>
            )}
          </Col>

          <Col lg={12} xs={24}>
            {customerMobile === '' && !state.disableForm ? (
              <Form.Item name="customerMobile" label="Customer Mobile Number">
                <Input />
              </Form.Item>
            ) : (
              <>
                <div className="label">Customer Mobile Number</div>
                <div className="box bg-box ">{customerMobile}</div>
              </>
            )}
          </Col>

          <Col lg={12} xs={24}>
            {customerEmail === '' && !state.disableForm ? (
              <Form.Item
                name="customerEmail"
                label="Customer Email"
                rules={[
                  {
                    message: 'Please enter a valid email address',
                    type: 'email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            ) : (
              <>
                <div className="label">Customer Email</div>
                <div className="box bg-box ">{customerEmail}</div>
              </>
            )}
          </Col>
          <Col lg={12} xs={24}>
            {customerRego === '' && !state.disableForm ? (
              <Form.Item name="customerRego" label="Vehicle Registration">
                <Input onChange={(e) => fieldUppercase(e, form, 'customerRego')} />
              </Form.Item>
            ) : (
              <>
                <div className="label">Vehicle Registration</div>
                <div className="box bg-box ">{customerRego}</div>
              </>
            )}
          </Col>
          <Col lg={12} xs={24}>
            {customerMakeAndModel === '' && !state.disableForm ? (
              <Form.Item name="customerMakeAndModel" label="Vehicle Make & Model">
                <Input onChange={(e) => fieldCapitalize(e, form, 'customerMakeAndModel', true)} />
              </Form.Item>
            ) : (
              <>
                <div className="label">Vehicle Make & Model</div>
                <div className="box bg-box ">{customerMakeAndModel}</div>
              </>
            )}
          </Col>

          <Col lg={24} xs={24}>
            {customerAddress === '' && !state.disableForm ? (
              <Address
                label="Customer Address"
                fieldsOptional={true}
                streetName="customerStreet"
                street2Name="customerStreetLine2"
                postCodeName="customerPostalCode"
                stateName="customerState"
                cityName="customerCity"
              />
            ) : (
              <>
                <div className="label">Customer Address</div>
                <div className="box bg-box ">{customerAddress}</div>
              </>
            )}
          </Col>

          <Col span={24}>
            <div className="border-t-dashed mt-16 pt-32 flex content-space-between middle">
              <h5 className="text-sm m-0">Claim Details</h5>
              {data.liabilityConfirmed && (
                <div className="text-global text-xs font-semibold icon-xs flex gap-8 middle">
                  <MyIcon icon="IconApproved" />
                  Liability is confirmed
                </div>
              )}
            </div>
          </Col>
          <Col lg={12} xs={24}>
            {customerClaimNumber === '' && !state.disableForm ? (
              <Form.Item name="customerClaimNumber" label="Customer Claim Number">
                <Input onChange={(e) => fieldUppercase(e, form, 'customerClaimNumber')} />
              </Form.Item>
            ) : (
              <>
                <div className="label">Customer Claim Number</div>
                <div className="box bg-box ">{customerClaimNumber}</div>
              </>
            )}
          </Col>
          <Col lg={12} xs={24}>
            {customerInsurer === '' && !state.disableForm ? (
              <Form.Item name="customerInsurer" label="Customer Insurer" style={{ position: 'relative' }}>
                <Select showSearch filterOption={filterOptions} onFocus={getInsurers} onClick={getInsurers}>
                  {insurers.length === 0 ? (
                    <Option key={'Loading...'} value={'Loading...'} disabled>
                      Loading...
                    </Option>
                  ) : (
                    insurers.map((option: ISelectOption) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>
            ) : (
              <>
                <div className="label">Customer Insurer</div>
                <div className="box bg-box ">{customerInsurer}</div>
              </>
            )}
          </Col>
          <Col lg={12} xs={24}>
            {atFaultPartyClaimNumber === '' && !state.disableForm ? (
              <Form.Item name="atFaultPartyClaimNumber" label="At Fault Claim Number">
                <Input onChange={(e) => fieldUppercase(e, form, 'atFaultPartyClaimNumber')} />
              </Form.Item>
            ) : (
              <>
                <div className="label">At Fault Claim Number</div>
                <div className="box bg-box ">{atFaultPartyClaimNumber}</div>
              </>
            )}
          </Col>
          <Col lg={12} xs={24}>
            {atFaultPartyInsurer === '' && !state.disableForm ? (
              <Form.Item name="atFaultPartyInsurer" label="At Fault Insurer" style={{ position: 'relative' }}>
                <Select showSearch filterOption={filterOptions} onFocus={getInsurers} onClick={getInsurers}>
                  {insurers.length === 0 ? (
                    <Option key={'Loading...'} value={'Loading...'} disabled>
                      Loading...
                    </Option>
                  ) : (
                    insurers.map((option: ISelectOption) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>
            ) : (
              <>
                <div className="label">At Fault Insurer</div>
                <div className="box bg-box ">{atFaultPartyInsurer}</div>
              </>
            )}
          </Col>
          <Col span={24}>
            <div className="border-t-dashed mt-16 pt-32">
              <h5 className="text-sm m-0">Documents</h5>
            </div>
          </Col>
          {!state.disableForm && (
            <>
              <Col span={24}>
                <p className="m-0">Here you can upload any documents that can support your referral.</p>
                <p className="text-grey m-0">
                  (Claim form (insurer), Drivers licences, Rego papers, Accident Photos, Tow Slip etc.)
                </p>
              </Col>
              <Col span={24}>
                <MultiFileUpload
                  form={form}
                  handleFilesUpdate={handleFilesUpdate}
                  FileOptions={[
                    { value: 'Rego Papers', label: 'Rego Papers' },
                    { value: 'Accident Photos', label: 'Accident Photos' },
                    { value: 'Claim Form', label: 'Claim Form' },
                    { value: 'Drivers Licence', label: 'Drivers Licence' },
                    { value: 'Tow Slip', label: 'Tow Slip' },
                    { value: 'Other', label: 'Other' },
                  ]}
                />
              </Col>
            </>
          )}
          {files.length === 0
            ? state.disableForm && (
                <Col span={24}>
                  <div className="box bg-box italic">No documents uploaded...</div>
                </Col>
              )
            : files.map((file) => {
                return (
                  <Col span={24}>
                    <div className="box bg-box gap-12">
                      <MyIcon icon="IconPreAuth" /> {file}
                    </div>
                  </Col>
                );
              })}
          <Col span={24}>
            <div className="border-t-dashed mt-16 pt-32">
              <h5 className="text-sm m-0">Repair Details</h5>
            </div>
          </Col>
          <Col lg={12} xs={24}>
            {repairStartDate === '' && !state.disableForm ? (
              <Form.Item name="repairStartDate" label="Repair Start Date">
                <DatePicker className="box" format="DD/MM/YYYY" placeholder="Select the date" showToday={false} />
              </Form.Item>
            ) : (
              <>
                <div className="label">Repair Start Date</div>
                <div className="box bg-box">{repairStartDate}</div>
              </>
            )}
          </Col>
          <Col lg={12} xs={24}>
            {damagedCarState === '' && !state.disableForm ? (
              <Form.Item name="damagedCarState" label="Damaged Car State">
                <Select placeholder="Select the damage car state">
                  <Option value="Assessing">Assessing</Option>
                  <Option value="Waiting for Authorisation">Waiting for Authorisation</Option>
                  <Option value="Total Loss">Total Loss</Option>
                  <Option value="Repairable">Repairable</Option>
                </Select>
              </Form.Item>
            ) : (
              <>
                <div className="label">Damaged Car State</div>
                <div className="box bg-box">{damagedCarState}</div>
              </>
            )}
          </Col>
          <Col lg={24} xs={24}>
            <div className="label">Repair End Date</div>
            <div className="box bg-box">{repairEndDate}</div>
          </Col>

          <Col xs={24} className="hide-label">
            <div className="border-t-dashed mt-16 pt-32 flex flex-column gap-12">
              {additionalComment === '' && !state.disableForm ? (
                <Form.Item name="additionalComments" label="Additional Comments">
                  <Input.TextArea
                    maxLength={32768}
                    rows={4}
                    placeholder="Leave any additional comments that will assist with your referral here"
                  />
                </Form.Item>
              ) : (
                <>
                  <div className="label">Additional Comments</div>
                  <div className="box-area bg-box">{additionalComment}</div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FormUpdateReferral;
