interface rightNodeProps {
  node: React.ReactNode;
  classContainer?: string;
  className?: string;
}

function FooterNavigation({ node, classContainer, className }: rightNodeProps) {
  return (
    <div id={'footer'} className={`fixed bottom-0 left-0 ${className ?? ''}`}>
      <div className="footer-contanier">
        <div className={`${classContainer ? classContainer : 'container-2'} end md-w-fix`}>{node}</div>
      </div>
    </div>
  );
}
export default FooterNavigation;
