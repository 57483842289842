import { Route, Routes, useLocation } from 'react-router-dom';
import React, { useEffect, useContext } from 'react';
import { NotFound, AppContent, SignIn, UserContext, IStringKeyNum } from '@scanny-app/loopy-loop';
import Dashboard from '../pages/Dashboard/Dashboard';
import Referrals from '../pages/Referrals/Referrals';
import CreateReferral from '../pages/Referrals/CreateReferral';
import ViewReferral from '../pages/Referrals/ViewReferral';

const pageTitles: { [key: string]: string } = {
  '*': 'Not Found',
  '/something-went-wrong': '500',
  '/signin': 'Sign In',
};
interface IAppRoutesProps {
  dashboardData: IStringKeyNum;
}
function AppRoutes({ dashboardData }: IAppRoutesProps) {
  const userData = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    let title = 'Cruize Control';
    for (const key in pageTitles) {
      if (location.pathname.includes(key)) {
        title = pageTitles[key];
        break;
      }
    }
    document.title = title;
    document.body.style.setProperty('--global-color', '#3CCEB3');
  }, [location.pathname]);
  const branchLocation = (
    <div id="sidebar-branch" className="flex flex-column bg-dark-grey-30 rounded-6 p-12 mb-12">
      <div className="text-xs text-white mb-12 pb-12 border-b-dark-grey-20">Your Carbiz Branch</div>
      <div className="flex gap-12 middle">
        <img src="/assets/img-logo-carbiz-circle.webp" alt="Carbiz Location" className="h-36 w-auto" />
        <div className="text-white flex flex-column gap-4">
          <div className="text-xs font-semibold">{userData?.Branch?.name}</div>
          <div className="text-xxs">
            {userData?.Branch?.street} {userData?.Branch?.city} {userData?.Branch?.postCode} Australia
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Routes>
      <Route
        path="/"
        element={<AppContent bottomNode={branchLocation} pageContent={<Dashboard dashboardData={dashboardData} />} />}
      />
      <Route
        path="/signin"
        element={
          <SignIn
            isFullPage={true}
            imagePath="/assets/img-cruize-control-hero.webp"
            imagePosition="center"
            includesTermsAndPrivacy
          />
        }
      />
      <Route path="///" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
      <Route
        path="/referrals"
        element={<AppContent bottomNode={branchLocation} pageContent={<Referrals />} pageName={'Referrals'} />}
      />
      <Route
        path="/referrals/create-referral"
        element={<AppContent bottomNode={branchLocation} pageContent={<CreateReferral />} />}
      />
      <Route path="/referrals/:id" element={<AppContent bottomNode={branchLocation} pageContent={<ViewReferral />} />} />
    </Routes>
  );
}

export default AppRoutes;
