import { MyIcon, ISideMenu } from '@scanny-app/loopy-loop';

function SideMenuList() {
  const data: ISideMenu[] = [
    {
      label: 'Dashboard',
      slug: '',
      icon: <MyIcon icon="IconDashboard" />,
      menu: [],
      link: '/',
    },
    {
      label: 'Referrals',
      slug: 'referrals',
      icon: <MyIcon icon="IconReferrals" />,
      menu: [],
      link: '/referrals',
      onSideMenuClickAction: () => {
        localStorage.removeItem('filter');
      },
    },
  ];

  return data;
}

export default SideMenuList;
