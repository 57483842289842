import React, { useEffect, useState } from 'react';
import DataTable from '../../components/Table/DataTable';
import { useNavigate } from 'react-router-dom';
import { REFERRAL_DATA_COLUMNS } from '../../components/Table/Columns';
import { IReferrals, ReferralContext } from '../../interfaces/Referrals';
import { message } from 'antd';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { LargeLoading, PrimaryNavigation } from '@scanny-app/loopy-loop';
import { ColumnsType } from 'antd/es/table';
interface ReferralsProps {
  toggleSidebar?: (() => void) | undefined;
  sidebarVisibleInMobile?: boolean | undefined;
}
function Referrals({ toggleSidebar, sidebarVisibleInMobile }: ReferralsProps) {
  const navigate = useNavigate();
  const [data, setData] = useState<IReferrals[]>([]);
  const [apiCompleted, setApiCompleted] = useState(false);
  const [widthMobile, setWidthMobile] = useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState<ColumnsType<IReferrals>>(() =>
    REFERRAL_DATA_COLUMNS(navigate, window.innerWidth <= 768),
  );

  const _rightNode = (
    <button className="btn-submit" onClick={() => navigate('/referrals/create-referral')}>
      Refer a customer
    </button>
  );

  useEffect(() => {
    let run = true;
    Auth.currentSession()
      .then((responseData) => {
        const token = responseData.getAccessToken().getJwtToken();
        axios
          .post(`${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Referral`, JSON.stringify({}), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (run) {
              setData(response.data);
              setApiCompleted(true);
            }
          })
          .catch(() => {
            message.error('Something went wrong. Please try again.');
          });
      })
      .catch(() => {
        message.error('Something went wrong. Please try again.');
      });
    return () => {
      run = false;
    };
  }, [apiCompleted]);

  useEffect(() => {
    setColumns(REFERRAL_DATA_COLUMNS(navigate, window.innerWidth <= 768));
  }, [widthMobile]);

  useEffect(() => {
    const handleResize = () => {
      setWidthMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isLoading && <LargeLoading customText={`Just a moment, we're updating the At fault claim number....`} />}
      <PrimaryNavigation
        toggleSidebar={toggleSidebar}
        sidebarVisible={sidebarVisibleInMobile}
        rightNode={_rightNode}
        menuTitle={`Referrals`}
        menuIcon={'IconReferrals'}
      />

      <div className="container-1 p-24 md-w-fix table-wrapper">
        <ReferralContext.Provider value={{ setReloadData: setApiCompleted, setIsLoading }}>
          <DataTable<IReferrals>
            type={'referral'}
            columns={columns}
            data={data}
            columnsToSearch={[
              'customerRego',
              'Booking__r.records.0.Vehicle__r.Name',
              'customerMobile',
              'atFaultPartyClaimNumber',
            ]}
            searchPlaceholder={'Customer Name, Customer Rego, Rental Rego, AF Claim Number, Customer Mobile'}
            combinationColumns={[['customerFirstName', 'customerLastName']]}
            className="md-rental-request"
            apiCompleted={apiCompleted}
            getRowKey={(record) => record.id.toString()}
          />
        </ReferralContext.Provider>
      </div>
    </>
  );
}

export default Referrals;
