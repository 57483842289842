import { MyIcon } from '@scanny-app/loopy-loop';

interface ThirdNavigationProps {
  menuTitle: string;
  rightNode?: React.ReactNode;
  onBack: () => void;
  borderBottom?: boolean;
  slug: string;
}

function ThirdNavigation({ onBack, rightNode, menuTitle, borderBottom = true, slug }: ThirdNavigationProps) {
  return (
    <div className="container-2 px-24 md-w-fix sticky top-0 z-50">
      <div id="top-navigation" className={borderBottom ? 'border-b' : ''}>
        <div className="flex flex-column gap-4">
          <div className="flex flex-row gap-8 icon-xs middle hover-pointer" onClick={onBack}>
            <MyIcon icon="IconBack" />
            <div className="text-xs">{slug}</div>
          </div>
          <h1 className="m-0">{menuTitle}</h1>
        </div>
        <div className="flex flex-row middle gap-4">{rightNode}</div>
      </div>
    </div>
  );
}

export default ThirdNavigation;
