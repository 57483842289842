import React, { useState, useEffect } from 'react';
import AppRoutes from './config/AppRoutes';
import { ConfigProvider, message } from 'antd';
import {
  AppConfigContext,
  IUser,
  IBranch,
  IReferralResponse,
  IStringKeyNum,
  LargeLoading,
  UserContext,
  EmptyUser,
  CompanyNotFound,
  SomethingWentWrong,
  HeadScript,
} from '@scanny-app/loopy-loop';
import SideMenuList from './config/SideMenuList';
import awsconfig from './aws-exports';
import { Amplify, Auth } from 'aws-amplify';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, Navigate } from 'react-router-dom';
import axios from 'axios';
Amplify.configure(awsconfig);

function App() {
  const [userData, setUserData] = useState<IUser>({} as IUser);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState<IStringKeyNum>({
    total: 0,
    New: 0,
    Assessing: 0,
    'On Hire': 0,
    'Off Hire': 0,
    Recovery: 0,
    Settled: 0,
    Booking: 0,
    Cancelled: 0,
    Terminated: 0,
    isLoading: 0,
  });
  const isAuthenticated = Object.keys(userData).length > 0 && userData.constructor === Object;
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const location = useLocation();
  const isSignInPage = location.pathname === '/signin';

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        Auth.currentSession()
          .then((currentSessionResponse) => {
            const token = currentSessionResponse.getAccessToken().getJwtToken();
            axios
              .post(`${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Company`, JSON.stringify({}), {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                if (response.data.length === 0) {
                  setUserData(() => EmptyUser);
                  setIsLoading(false);
                } else {
                  const branch: IBranch = {
                    id: response.data[0].branchId,
                    name: response.data[0].branchName,
                    timeZone: response.data[0].branchTimeZone,
                    state: response.data[0].billingState,
                    street: response.data[0].billingStreet,
                    city: response.data[0].billingCity,
                    postCode: response.data[0].billingPostalCode,
                  };
                  const user = {
                    email: currentSessionResponse.getIdToken().payload.email,
                    firstName: currentSessionResponse.getIdToken().payload.given_name,
                    lastName: currentSessionResponse.getIdToken().payload.family_name,
                    currentCompany: currentSessionResponse.getIdToken().payload['custom:COMPANY_SF_ID'],
                    companyName: response.data[0].name,
                    Branch: branch,
                  };
                  setUserData(user);
                  setIsLoading(false);
                }
              })
              .catch((error) => {
                setUserData(() => EmptyUser);
                setIsLoading(false);
                if (error.response.data.message !== 'Invalid custom attribute') {
                  setSomethingWentWrong(true);
                }
              });
          })
          .catch(() => {
            setIsLoading(false);
            setUserData(() => EmptyUser);
            setSomethingWentWrong(false);
          });
      })
      .catch(() => {
        setIsLoading(false);
        setSomethingWentWrong(false);
      });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      Auth.currentSession().then((sessionResponse) => {
        const token = sessionResponse.getAccessToken().getJwtToken();
        axios
          .get(`${process.env.REACT_APP_ENDPOINT_URL}/v1/get/dashboard`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            let total = 0;
            if (response) {
              const transformedData = response.data.compositeResponse[0].body.records.reduce(
                (acc: IStringKeyNum, curr: IReferralResponse) => {
                  acc[curr.Accident_Stage__c] = curr.expr0;
                  total += curr.expr0;
                  return acc;
                },
                {},
              );
              transformedData['total'] = total;
              setDashboardData(transformedData);
            }
          })
          .catch(() => {
            message.error('Error fetching the dashboard data. Please try again later');
          });
      });
    };
    if (isAuthenticated && userData.currentCompany !== 'NOT_FOUND') fetchData();
  }, [isAuthenticated]);
  const theme = {
    colorPrimary: '#3CCEB3',
    fontFamily: 'Inter, sans-serif',
  };
  const appConfig = {
    SideMenuList: SideMenuList(),
    logoImagePath: '/assets/cruize-logo.svg',
    showSideBarText: true,
    sideBarBackgroundColour: '#1A1919',
    onLogoutAction: () => localStorage.removeItem('filter'),
    feedbackButton: true,
  };

  const onIdle = () => {
    if (isAuthenticated) {
      Auth.signOut().then(() => {
        if (appConfig.onLogoutAction) appConfig.onLogoutAction();
        window.location.reload();
      });
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 7200000,
  });
  if (isLoading) return <LargeLoading />;
  if (!isLoading && somethingWentWrong) return <SomethingWentWrong />;
  if (!isLoading && !isAuthenticated && !isSignInPage) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  if (!isLoading && isAuthenticated && isSignInPage) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  if (!isLoading && isAuthenticated && !isSignInPage && userData.currentCompany === 'NOT_FOUND') {
    return <CompanyNotFound />;
  }
  return (
    <div className="App">
      <HeadScript
        googleAnalyticsId={{ dev: 'G-5E4CFNKNVB', prod: 'G-KXRLR5G6G8' }}
        microsoftClarityId={{ dev: 'ov5zlcocof', prod: 'nx9ljziwhg' }}
      />
      <ConfigProvider
        theme={{
          token: theme,
        }}
      >
        <AppConfigContext.Provider value={appConfig}>
          <UserContext.Provider value={userData}>
            <AppRoutes dashboardData={dashboardData}></AppRoutes>
          </UserContext.Provider>
        </AppConfigContext.Provider>
      </ConfigProvider>
    </div>
  );
}

export default App;
