import { Modal, Form, Col, Input, Row, FormInstance, Select } from 'antd';
import { filterOptions, GetOptionsFromDB, ISelectOption } from '@scanny-app/loopy-loop';
import { useState } from 'react';

interface EditFieldProps {
  open: boolean;
  onCancel: () => void;
  isLoading: boolean;
  onSubmit: () => void;
  data: { label: string; name: string };
  form: FormInstance;
}

function PopupMissingDetails({ open, onCancel, onSubmit, isLoading, data, form }: EditFieldProps) {
  const { Option } = Select;
  const [insurers, setInsurers] = useState<ISelectOption[]>([]);
  const getInsurers = async () => {
    if (insurers.length === 0) {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Insurer`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: {},
        },
        (item) => item.name,
      );
      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      setInsurers(sortedOptions);
    }
  };

  return (
    <Modal
      className="custom-modal left-menu"
      width={360}
      centered
      okText="Save"
      cancelText="Cancel"
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      okButtonProps={{ disabled: isLoading }}
    >
      <Form disabled={isLoading} form={form} className="w-full form-style-one" layout="vertical">
        <Row gutter={[16, 12]}>
          <Col span={24}>
            <h6 className="m-0 text-sm font-semibold">Enter the {data.label}</h6>
          </Col>
          <Col span={24}>
            {data.name === 'customerInsurer' || data.name === 'atFaultPartyInsurer' ? (
              <Form.Item
                name={data.name}
                label={data.label}
                style={{ position: 'relative' }}
                rules={[{ required: true, message: 'Please select the option' }]}
              >
                <Select showSearch filterOption={filterOptions} onFocus={getInsurers} onClick={getInsurers}>
                  {insurers.length === 0 ? (
                    <Option key={'Loading...'} value={'Loading...'} disabled>
                      Loading...
                    </Option>
                  ) : (
                    insurers.map((option: ISelectOption) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item name={data.name} label={data.label} rules={[{ required: true, message: 'Please enter the field' }]}>
                <Input className="uppercase" />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default PopupMissingDetails;
