import React from 'react';
import { Modal } from 'antd';
interface PreviewModalProps {
  visible: boolean;
  file: File;
  onClose: () => void;
}
const PreviewModal = ({ visible, file, onClose }: PreviewModalProps) => {
  return (
    <Modal title={`Preview - ${file.name}`} open={visible} onCancel={onClose} footer={null}>
      <div>
        {file && file.type.startsWith('image/') && (
          <img src={URL.createObjectURL(file)} alt={file.name} style={{ width: '100%' }} />
        )}
        {file && file.type === 'application/pdf' && (
          <iframe src={URL.createObjectURL(file) + '#toolbar=0&navpanes=0'} width="100%" height="640px" />
        )}
      </div>
    </Modal>
  );
};

export default PreviewModal;
