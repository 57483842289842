import { Form, Input, Modal, message } from 'antd';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { MyIcon } from '@scanny-app/loopy-loop';
import { useContext, useState } from 'react';
import { ReferralContext } from '../../interfaces/Referrals';

export interface IFormUpdateAFClaim {
  id: string;
}

function FormUpdateAFClaim({ id }: IFormUpdateAFClaim) {
  const [formAFClaim] = Form.useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { setReloadData, setIsLoading } = useContext(ReferralContext);

  const handleSubmit = () => {
    if (setIsLoading) {
      setIsLoading(true);
      updateAFClaim(id, setIsLoading);
      setShowConfirmation(false);
    }
  };

  const updateAFClaim = async (id: string, setIsLoading: (value: boolean) => void) => {
    try {
      const sessionData = await Auth.currentSession();
      const token = sessionData.getAccessToken().getJwtToken();
      const atFaultPartyClaimNumber = formAFClaim.getFieldValue('atFaultPartyClaimNumber');
      const formatValue = { atFaultPartyClaimNumber: atFaultPartyClaimNumber.toUpperCase() };

      await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/update/Referral/${id}`,
        JSON.stringify({ ...formatValue, id: id }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setIsLoading(false);
      setReloadData((prevState) => !prevState);
      setShowConfirmation(false);
      message.success('At Fault Claim Number updated successfully');
    } catch (error) {
      setIsLoading(false);
      setShowConfirmation(false);
      message.error('Error updating the at fault claim number');
    }
  };

  return (
    <>
      <Modal
        open={showConfirmation}
        onCancel={() => setShowConfirmation(false)}
        onOk={handleSubmit}
        className="custom-modal"
        width={400}
        centered
      >
        <h5 className="text-sm font-semibold m-0">Are you sure you want to update AF Claim Number?</h5>
        <p className="mt-4 mb-16">This action will save the At fault claim number to this referral and cannot be undone.</p>
      </Modal>
      <Form
        form={formAFClaim}
        className="w-full flex flex-row h-32"
        onFinish={() => setShowConfirmation(true)}
        id="afClaimNumberForm"
      >
        <Form.Item name="atFaultPartyClaimNumber">
          <Input placeholder={'Enter AF claim number'} className="green-field h-32 rounded-6006 uppercase" />
        </Form.Item>

        <button className="btn-global h-32 rounded-6 icon-xs text-white rounded-0660 hover-pointer" type="submit">
          <MyIcon icon={'IconApproved'} />
        </button>
      </Form>
    </>
  );
}

export default FormUpdateAFClaim;
