import { ISelectOption, MyIcon, PreviewFile, IFile, convertToBase64 } from '@scanny-app/loopy-loop';
import { DragEvent, useCallback, useState, useEffect, useRef, ChangeEvent } from 'react';
import { FormInstance } from 'antd';
interface MultiFileUploadProps {
  FileOptions: ISelectOption[];
  handleFilesUpdate: (files: IFile[]) => void;
  form: FormInstance;
}
function MultiFileUpload({ FileOptions, form, handleFilesUpdate }: MultiFileUploadProps) {
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const onDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    addFiles(event.dataTransfer.files);
    setIsFocused(false);
  }, []);

  const focusElement = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsFocused(true);
  };
  const unFocusElement = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsFocused(false);
  };
  const handleFile = (file: File) => {
    setFiles((prevFiles) => [...prevFiles, file]);
    const reader = new FileReader();
    reader.readAsDataURL(file);
  };
  const addFiles = (files: FileList | null) => {
    if (!files) return;
    for (let i = 0; i < files?.length; i++) {
      if (files?.[i]) {
        handleFile(files?.[i]);
      }
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    addFiles(event.target.files);
  };
  const deleteIndex = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
    form.resetFields([`files[${index}].name`]);
  };
  const updateName = (value: string, index: number) => {
    if (value === 'Other') {
      form.setFieldsValue({ [`files[${index}].name`]: '' });
      return;
    }
    setFiles((prevFiles) =>
      prevFiles.map((file, i) => {
        if (i === index) {
          const extension = file.name.split('.').pop();
          return new File([file], `${value}.${extension}`, { type: file.type });
        }
        return file;
      }),
    );
  };
  const onZoneClick = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    const convertFiles = async (files: File[]) => {
      const customFiles: IFile[] = [];

      for (const file of files) {
        const base64Data = await convertToBase64(file);
        customFiles.push({
          title: file.name.split('.')[0] || 'Document',
          name: file.name,
          data: base64Data,
        });
      }
      handleFilesUpdate(customFiles);
    };
    convertFiles(files);
  }, [files]);
  return (
    <div className="gap-8 flex flex-column">
      <div
        className={`noselect w-full content-center h-55 flex middle gap-12 flex-row btn-status font-normal`}
        style={{
          backgroundColor: isFocused ? '#3CCEB3' : '#FFFFFF',
          color: isFocused ? '#FFFFFF' : '#3CCEB3',
          border: '1px dashed #3CCEB3',
          boxSizing: 'border-box',
        }}
        onClick={onZoneClick}
        onDrop={onDrop}
        onDragOver={focusElement}
        onDragEnd={unFocusElement}
        onDragLeave={unFocusElement}
        onMouseEnter={focusElement}
        onMouseLeave={unFocusElement}
      >
        Upload documents
        <MyIcon icon="IconUploadCloud" fill={isFocused ? '#FFFFFF' : '#3CCEB3'} />
      </div>
      {files.length > 0 && (
        <div>
          <div className="grid-1fr-2fr divider-dropdown">
            <h4 className="font-normal" style={{ marginBottom: '5px' }}>
              Type
            </h4>
            <h4 className="font-normal" style={{ marginBottom: '5px' }}>
              Document
            </h4>
          </div>
          {files.map((file, index) => (
            <PreviewFile
              key={index}
              index={index}
              file={file}
              FileOptions={FileOptions}
              deleteIndex={() => deleteIndex(index)}
              updateName={(value) => updateName(value, index)}
            />
          ))}
        </div>
      )}
      <input
        accept="image/*,application/pdf"
        multiple
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
    </div>
  );
}

export default MultiFileUpload;
