import { Col, Form, FormInstance, Input, Radio } from 'antd';
import { Address, capitalizeWords } from '@scanny-app/loopy-loop';
interface ReferCustomerProps {
  form: FormInstance;
}
function ReferCustomer({ form }: ReferCustomerProps) {
  return (
    <>
      <Col span={24}>
        <Form.Item name="isPronto" initialValue={false}>
          <Radio.Group className="radio-button-group">
            <Radio value={false}>Customer needs a car later</Radio>
            <Radio value={true}>Customer needs a car now!</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={24}>
        <h5 className="m-0 text-sm">Customer Details</h5>
      </Col>
      <Col lg={12} xs={24}>
        <Form.Item name="customerFirstName" label="Customer First Name">
          <Input onChange={(e) => form.setFieldsValue({ customerFirstName: capitalizeWords(e.target.value, true) })} />
        </Form.Item>
      </Col>
      <Col lg={12} xs={24}>
        <Form.Item name="customerLastName" label="Customer Last Name">
          <Input onChange={(e) => form.setFieldsValue({ customerLastName: capitalizeWords(e.target.value, true) })} />
        </Form.Item>
      </Col>
      <Col lg={12} xs={24}>
        <Form.Item
          name="customerEmail"
          label="Customer Email Address"
          rules={[
            {
              message: 'Please enter a valid email address',
              type: 'email',
            },
          ]}
        >
          <Input onChange={(e) => form.setFieldsValue({ customerEmail: e.target.value.toLowerCase() })} />
        </Form.Item>
      </Col>
      <Col lg={12} xs={24}>
        <Form.Item
          name="customerMobile"
          label="Customer Mobile Number"
          rules={[
            {
              required: true,
              message: 'Please enter the customer mobile number',
            },
            {
              pattern: /^\(?(?:\+?61|0)4\)?[ -]?[0-9]{7}[0-9]$/,
              message: 'Please enter a valid phone number',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col lg={12} xs={24}>
        <Form.Item name="customerRego" label="Vehicle Registration">
          <Input onChange={(e) => form.setFieldsValue({ customerRego: e.target.value.toUpperCase() })} />
        </Form.Item>
      </Col>
      <Col lg={12} xs={24}>
        <Form.Item name="customerMakeAndModel" label="Vehicle Make and Model">
          <Input onChange={(e) => form.setFieldsValue({ customerMakeAndModel: capitalizeWords(e.target.value, true) })} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Address
          label="Customer Address"
          fieldsOptional={true}
          streetName="customerStreet"
          street2Name="customerStreetLine2"
          postCodeName="customerPostalCode"
          stateName="customerState"
          cityName="customerCity"
        />
      </Col>
    </>
  );
}

export default ReferCustomer;
